import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { DataStore, SortDirection } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { RootParams } from '~/App';
import { Button, Compound, Container, Logo, Stack, Text } from '~/components';
import { ListItem } from '~/components/ListItem';
import { Distance, Style } from '~/constants';
import { Values } from '~/constants/Values';
import { Utility } from '~/methods';
import { Flight, Pilot } from '~/models';
import { useAuth } from '~/provider';

type StartProps = NativeStackScreenProps<RootParams, 'start'>;

const containerStyle = Style.apply('flex');
const logoStyle = Style.apply('center');
const unauthorizedStyle = Style.apply(['flex', 'center']);

export const Start = ({ navigation, route }: StartProps) => {
  const { isCurrent, setJwt } = useAuth();

  useEffect(() => {
    if (route.params?.jwt) {
      setJwt(route.params.jwt);
      AsyncStorage.setItem('jwt', route.params.jwt);
    }
  }, [route.params]);

  return (
    <>
      <Container preset={'silver'} style={logoStyle}>
        <Logo />
      </Container>
      {isCurrent ? (
        <Main navigation={navigation} route={route} />
      ) : (
        <Container style={unauthorizedStyle}>
          <Stack distance={Distance.v20}>
            <Text preset={'title'} textAlign={'center'}>
              Du bist nicht angemeldet
            </Text>
            <Text textAlign={'center'}>
              Rufe die Anwendung über Vereinsflieger auf.
            </Text>
          </Stack>
        </Container>
      )}
      <Container preset={'silver'}>
        <Text
          preset={'disclaimer'}
          onPress={() => {
            navigation.navigate('terms');
          }}
        >
          &copy; {new Date().getFullYear()} Jörg Goy, Nutzung & Datenschutz
        </Text>
      </Container>
    </>
  );
};

const getGroups = (flights: Flight[]) => {
  const groups: { [key: string]: Flight[] } = {};
  flights.forEach((flight) => {
    const month = new Date(flight.start.substring(0, 7)).toLocaleDateString(
      'de',
      { year: 'numeric', month: 'long' },
    );
    if (!groups[month]) {
      groups[month] = [];
    }
    groups[month].push(flight);
  });
  return Object.entries(groups);
};

const Main = ({ navigation }: StartProps) => {
  const { user } = useAuth();
  const [pilot, setPilot] = useState<Pilot>();
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [flights, setFlights] = useState<Flight[]>([]);
  const [aircraftFilter, setAircraftFilter] = useState<string[]>([]);
  const [typesFilter, setTypesFilter] = useState<string[]>([]);

  const hasFilters = aircraftFilter.length > 0 || typesFilter.length > 0;
  const notifications = (pilot?.notifications ?? []) as string[];
  const pilotFlights = flights.filter((flight) => flight.pilot === pilot?.id);
  const memberFlights = flights.filter((flight) => flight.pilot !== pilot?.id);
  const filteredMemberFlights = memberFlights.filter((flight) => {
    const types = (flight.types || []) as string[];
    if (
      (aircraftFilter.length > 0 &&
        !aircraftFilter.includes(flight.aircraft)) ||
      (typesFilter.length > 0 &&
        types.filter((type) => typesFilter.includes(type)).length < 1)
    ) {
      return false;
    }
    return true;
  });

  const groupedFlights = getGroups(filteredMemberFlights);

  const createPilot = () => {
    DataStore.save(new Pilot(user));
  };

  const updatePilot = (props: Partial<Pilot>) => {
    if (pilot) {
      DataStore.save(
        Pilot.copyOf(pilot, (updated) => {
          Object.entries(user).forEach(([key, value]) => {
            updated[key] = value;
          });
          Object.entries(props).forEach(([key, value]) => {
            updated[key] = value;
          });
        }),
      );
    }
  };

  useEffect(() => {
    if (user) {
      const subscription = DataStore.observeQuery(Pilot).subscribe(
        ({ items, isSynced }) => {
          setPilots(items);
          const pilot = items.filter((item) => item.email === user.email)[0];
          if (isSynced && !pilot) {
            createPilot();
          }
          setPilot(pilot);
        },
      );
      return () => subscription.unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const subscription = DataStore.observeQuery(
        Flight,
        (predicate) => predicate.start.gt(Utility.today()),
        {
          sort: (s) => s.start(SortDirection.ASCENDING),
        },
      ).subscribe(({ items }) => {
        setFlights(items);
      });
      return () => subscription.unsubscribe();
    }
  }, [user]);

  return (
    <ScrollView>
      <Container style={containerStyle}>
        <Stack distance={Distance.v40}>
          <Stack distance={Distance.v20}>
            <Text preset={'title'}>Hallo {user.firstName}!</Text>
            <Text>
              Keine Lust alleine zu fliegen? FSVH Connect ist unsere interne
              Vereinsplatform, um sich zu gemeinsamen Flügen zu verabreden und
              unvergessliche Flugerlebnisse zu teilen.
            </Text>
          </Stack>
          <Stack distance={Distance.v20}>
            <Text preset={'title'}>Benachrichtigungen</Text>
            <Text>
              Wähle aus, zu welcher Art von Flugangeboten du per E-Mail
              informiert werden möchtest.
            </Text>
            <>
              <Compound>
                {Values.aircraft.map((topic, index) => {
                  const isActive = Utility.oneOf(topic, notifications);
                  return (
                    <Button
                      key={index.toString()}
                      onPress={() => {
                        if (!isActive) {
                          updatePilot({
                            notifications: JSON.stringify([
                              ...notifications,
                              topic,
                            ]),
                          });
                        } else {
                          updatePilot({
                            notifications: JSON.stringify(
                              notifications.filter((value) => value !== topic),
                            ),
                          });
                        }
                      }}
                    >
                      <Text preset={isActive ? 'tag-active' : 'tag-inactive'}>
                        {topic}
                      </Text>
                    </Button>
                  );
                })}
              </Compound>
              <Compound>
                {Values.types.map((topic, index) => {
                  const isActive = Utility.oneOf(topic, notifications);
                  return (
                    <Button
                      key={index.toString()}
                      onPress={() => {
                        if (!isActive) {
                          updatePilot({
                            notifications: JSON.stringify([
                              ...notifications,
                              topic,
                            ]),
                          });
                        } else {
                          updatePilot({
                            notifications: JSON.stringify(
                              notifications.filter((value) => value !== topic),
                            ),
                          });
                        }
                      }}
                    >
                      <Text preset={isActive ? 'tag-active' : 'tag-inactive'}>
                        {topic}
                      </Text>
                    </Button>
                  );
                })}
              </Compound>
            </>
          </Stack>
          <Stack distance={Distance.v20}>
            <Text preset={'title'}>Deine Flüge</Text>
            <Text>Lass uns wissen, was Du vor hast.</Text>
            <Stack distance={Distance.v20}>
              {getGroups(pilotFlights).map(([month, flights], index) => {
                return (
                  <Stack key={index.toString()} distance={Distance.v10}>
                    <Text preset={'heading'}>{month}</Text>
                    <Stack key={index.toString()} distance={Distance.v10}>
                      {flights.map((flight, index) => {
                        const pilot = pilots.filter(
                          (pilot) => pilot.id === flight.pilot,
                        )[0];

                        return (
                          <Button
                            key={index.toString()}
                            onPress={() => {
                              navigation.navigate('edit', { id: flight.id });
                            }}
                          >
                            <ListItem {...{ flight, pilot, navigation }} />
                          </Button>
                        );
                      })}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
            <Button
              onPress={() => {
                navigation.navigate('edit', { id: Values.newId });
              }}
            >
              <Text preset={'tag-active'}>Neuen Flug anlegen</Text>
            </Button>
          </Stack>
          <Stack distance={Distance.v20}>
            <Stack preset={'row'}>
              <Text preset={'title'}>Mitglieder Angebote</Text>
            </Stack>
            <Text>Filtere die Angebote nach Deinen Präferenzen.</Text>
            <>
              <Compound>
                {Values.aircraft.map((filter, index) => {
                  const isActive = Utility.oneOf(filter, aircraftFilter);
                  return (
                    <Button
                      key={index.toString()}
                      onPress={() => {
                        if (!isActive) {
                          setAircraftFilter((prevFilter) => [
                            ...prevFilter,
                            filter,
                          ]);
                        } else {
                          setAircraftFilter((prevFilter) =>
                            prevFilter.filter(
                              (prevFilter) => prevFilter !== filter,
                            ),
                          );
                        }
                      }}
                    >
                      <Text preset={isActive ? 'tag-active' : 'tag-inactive'}>
                        {filter}
                      </Text>
                    </Button>
                  );
                })}
              </Compound>
              <Compound>
                {Values.types.map((filter, index) => {
                  const isActive = Utility.oneOf(filter, typesFilter);
                  return (
                    <Button
                      key={index.toString()}
                      onPress={() => {
                        if (!isActive) {
                          setTypesFilter((prevFilter) => [
                            ...prevFilter,
                            filter,
                          ]);
                        } else {
                          setTypesFilter((prevFilter) =>
                            prevFilter.filter(
                              (prevFilter) => prevFilter !== filter,
                            ),
                          );
                        }
                      }}
                    >
                      <Text preset={isActive ? 'tag-active' : 'tag-inactive'}>
                        {filter}
                      </Text>
                    </Button>
                  );
                })}
              </Compound>
              <Button
                onPress={() => {
                  setAircraftFilter([]);
                  setTypesFilter([]);
                }}
              >
                <Text preset={!hasFilters ? 'tag-active' : 'tag-inactive'}>
                  Alle
                </Text>
              </Button>
            </>
            <Stack distance={Distance.v20}>
              {groupedFlights.length > 0 ? (
                groupedFlights.map(([month, flights], index) => {
                  return (
                    <Stack key={index.toString()} distance={Distance.v10}>
                      <Text preset={'heading'}>{month}</Text>
                      <Stack key={index.toString()} distance={Distance.v10}>
                        {flights.map((flight, index) => {
                          const pilot = pilots.filter(
                            (pilot) => pilot.id === flight.pilot,
                          )[0];

                          return (
                            <Button
                              key={index.toString()}
                              onPress={() => {
                                navigation.navigate('show', { id: flight.id });
                              }}
                            >
                              <ListItem {...{ flight, pilot, navigation }} />
                            </Button>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })
              ) : (
                <Text preset={'heading'}>
                  Keine {hasFilters && 'passenden '}Flüge vorhanden{' '}
                </Text>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </ScrollView>
  );
};
