import * as React from "react";
import Svg, { SvgProps, G, Rect, Path } from "react-native-svg";
export const SvgAdd = (props: SvgProps) => (
  <Svg width="34px" height="34px" viewBox="0 0 34 34" {...props}>
    <G id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G
        id="iPad-Air-10.9\u201D-Copy"
        transform="translate(-285.000000, -549.000000)"
      >
        <G id="add" transform="translate(285.000000, 549.000000)">
          <Rect
            id="Rectangle-Copy-3"
            fill="#EBEBED"
            x={0}
            y={0}
            width={34}
            height={34}
            rx={10}
          />
          <Path
            d="M17,10.15625 C17.6568358,10.15625 18.1875,10.6869136 18.1875,11.34375 L18.1875,16.6875 L23.53125,16.6875 C24.1880864,16.6875 24.71875,17.2181636 24.71875,17.875 C24.71875,18.5318364 24.1880864,19.0625 23.53125,19.0625 L18.1875,19.0625 L18.1875,24.40625 C18.1875,25.0630864 17.6568358,25.59375 17,25.59375 C16.3431642,25.59375 15.8125,25.0630864 15.8125,24.40625 L15.8125,19.0625 L10.46875,19.0625 C9.81191418,19.0625 9.28125,18.5318364 9.28125,17.875 C9.28125,17.2181642 9.81191418,16.6875 10.46875,16.6875 L15.8125,16.6875 L15.8125,11.34375 C15.8125,10.6869136 16.3431642,10.15625 17,10.15625 Z"
            id="Combined-Shape"
            fill="#7E7E80"
          />
        </G>
      </G>
    </G>
  </Svg>
);
