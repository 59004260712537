class TokenBridge {
  jwt?: string;

  constructor() {}

  setToken = (jwt?: string) => {
    this.jwt = jwt;
  };

  refreshToken = () => {
    return { token: this.jwt ?? 'no-token-present' };
  };
}

export const tokenBridge = new TokenBridge();
