import { registerRootComponent } from 'expo';
import { App } from './src/App';
import { Amplify } from '@aws-amplify/core';
import config from './src/aws-exports';
import { DataStore } from 'aws-amplify';
import { tokenBridge } from '~/provider';

Amplify.configure(config);
DataStore.configure({
  maxRecordsToSync: Math.pow(10, 9), // 1 Billion
  fullSyncInterval: 30,
  authProviders: {
    functionAuthProvider: tokenBridge.refreshToken,
  },
});

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
