import { ModelInit, MutableModel } from '@aws-amplify/datastore';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DefaultValues,
  FieldValues,
  Path,
  Resolver,
  UseFormReturn,
} from 'react-hook-form';
import type { AnyObject, ObjectShape } from 'yup';
import * as yup from 'yup';

type YupSchema =
  | yup.NumberSchema<
      number | null | undefined,
      AnyObject,
      number | null | undefined
    >
  | yup.StringSchema<
      string | null | undefined,
      AnyObject,
      string | null | undefined
    >;

type ValidationRule<FormData extends FieldValues = FieldValues> = {
  field: keyof FormData;
  type: 'string' | 'stringArray';
  required?: boolean;
  skipMessage?: boolean;
  message?: string;
};

interface CreateFormProps<FormData extends FieldValues = FieldValues> {
  initialValues: (keyof FormData | Partial<FormData>)[];
  validationRules: ValidationRule<FormData>[];
}

interface CreateFormReturnValue<FormData extends FieldValues = FieldValues> {
  defaultValues: DefaultValues<FormData>;
  resolver: Resolver<FormData>;
}

const getSchema = (type: ValidationRule['type']) => {
  switch (type) {
    case 'stringArray':
      return yup.string().not([JSON.stringify([])]);

    default:
      return yup.string();
  }
};

const setRequired = (
  schema: YupSchema,
  required?: boolean,
  errorMessage?: string,
) => {
  return required ? schema.required(errorMessage) : schema;
};

const create = <FormData extends FieldValues = FieldValues>({
  initialValues,
  validationRules,
}: CreateFormProps<FormData>): CreateFormReturnValue<FormData> => {
  const defaultValues: DefaultValues<FormData> = {
    ...Object.assign(
      {},
      ...initialValues.filter((value) => typeof value === 'object'),
    ),
  } as any;

  initialValues
    .filter((value) => typeof value === 'string')
    .forEach((key) => {
      (defaultValues as any)[key] = null;
    });

  const objectShape: ObjectShape = {};

  validationRules.forEach(({ field, type, message, skipMessage, required }) => {
    const errorMessage = !skipMessage ? message : undefined;
    let schema: YupSchema = getSchema(type).nullable();
    schema = setRequired(schema, required, errorMessage);
    objectShape[field as string] = schema;
  });
  const validationSchema = yup.object(objectShape);

  return { defaultValues, resolver: yupResolver(validationSchema) };
};

export declare class AnyModel {
  readonly id: string;
  readonly _version?: string;
  constructor(init: ModelInit<AnyModel>);
  static copyOf(
    source: AnyModel,
    mutator: (draft: MutableModel<AnyModel>) => MutableModel<AnyModel> | void,
  ): AnyModel;
}

interface FormPopulateFromModelProps<
  FormData extends FieldValues = FieldValues,
  TContext extends object = object,
> {
  form: UseFormReturn<FormData, TContext>;
  model: AnyModel;
}

const populateFromModel = <FormData extends FieldValues = FieldValues>({
  form,
  model,
}: FormPopulateFromModelProps<FormData>) => {
  Object.keys(form.getValues()).forEach((key) => {
    const value = model[key];
    if (value !== undefined) {
      form.setValue(key as Path<FormData>, value);
    }
  });
};

interface FormUpdateModelProps {
  formData: FieldValues;
  updated: MutableModel<AnyModel>;
}

const updateModel = ({ formData, updated }: FormUpdateModelProps) => {
  Object.keys(formData).forEach((key) => {
    updated[key] = formData[key];
  });
};

export const Form = { create, populateFromModel, updateModel };
