// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Pilot, Flight } = initSchema(schema);

export {
  Pilot,
  Flight
};