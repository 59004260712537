import React, { PropsWithChildren } from 'react';
import {
  Text as RNText,
  TextProps as RNTextProps,
  TextStyle,
} from 'react-native';
import { Distance, Font, FontSize, Style, Styles } from '~/constants';
import { useTheme } from '~/hooks';

export type TextPreset =
  | 'default'
  | 'strong'
  | 'title'
  | 'heading'
  | 'tag-text'
  | 'tag-active'
  | 'tag-inactive'
  | 'tag-negative'
  | 'disclaimer';

export type TextProps = PropsWithChildren<
  RNTextProps & {
    preset?: TextPreset;
    textAlign?: TextStyle['textAlign'];
    noWrap?: boolean;
    noWordBreak?: boolean;
    selectable?: boolean;
  }
>;

export const Text = ({
  preset = 'default',
  selectable = false,
  noWrap,
  noWordBreak,
  textAlign = 'left', // respects RTL
  style,
  ...props
}: TextProps) => {
  const { colors } = useTheme();

  const getTextStyle = (): TextStyle => {
    switch (preset) {
      case 'title':
        return {
          fontFamily: Font.bold,
          fontSize: FontSize.large,
          color: colors.title,
        };
      case 'heading':
        return {
          fontFamily: Font.bold,
          fontSize: FontSize.medium,
          color: colors.heading,
        };

      case 'tag-text':
      case 'tag-active':
      case 'tag-inactive':
      case 'tag-negative':
        return {
          ...Styles.tag,
          fontFamily: preset === 'tag-text' ? Font.regular : Font.bold,
          color:
            preset === 'tag-active'
              ? colors.primary
              : preset === 'tag-inactive'
              ? colors.textInactive
              : preset === 'tag-negative'
              ? colors.notification
              : colors.text,
          backgroundColor:
            preset === 'tag-active'
              ? colors.backgroundActive
              : preset === 'tag-inactive'
              ? colors.backgroundInactive
              : preset === 'tag-negative'
              ? colors.backgroundNegative
              : undefined,
          paddingHorizontal:
            preset === 'tag-text' ? Distance.hitSlop : Distance.h10,
          overflow: preset !== 'tag-text' ? 'hidden' : undefined,
        };
      case 'disclaimer':
        return {
          fontFamily: Font.regular,
          fontSize: FontSize.regular,
          color: colors.textInactive,
        };
      case 'strong':
        return {
          fontFamily: Font.medium,
          fontSize: FontSize.regular,
          color: colors.heading,
        };
      case 'default':
      default:
        return {
          fontFamily: Font.regular,
          fontSize: FontSize.regular,
          color: colors.text,
        };
    }
  };

  const textStyle = Style.apply([{ textAlign }, getTextStyle(), style]);

  return (
    <RNText
      {...props}
      children={
        noWordBreak && typeof props.children === 'string'
          ? props.children.replace(' ', '\u00A0')
          : props.children
      }
      style={textStyle}
      numberOfLines={noWrap ? 1 : props.numberOfLines}
      selectable={selectable}
      selectionColor={colors.primary}
    />
  );
};
