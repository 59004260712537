import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ScrollView } from 'react-native';
import { RootParams } from '~/App';
import { Button, Close, Container, Stack, Text } from '~/components';
import { Distance, Style } from '~/constants';
type TermProps = NativeStackScreenProps<RootParams, 'terms'>;

const closeStyle = Style.apply('close');

export const Terms = ({ navigation }: TermProps) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <Container preset={'modal'}>
        <Button
          style={closeStyle}
          onPress={() => {
            navigation.navigate('start');
          }}
        >
          <Stack preset={'row'} distance={Distance.h10} alignItems>
            <Text preset={'disclaimer'}>Schließen</Text>
            <Close />
          </Stack>
        </Button>
        <Html />
      </Container>
    </ScrollView>
  );
};

const Html = () => {
  return (
    <Text>
      <h1>Nutzungsbedingungen</h1>
      <p>
        FSVH Connect ist eine interne Webanwendung, die ausschließlich
        Mitgliedern des Flugsportvereins Herrenberg zur Verfügung steht. Die
        Nutzung durch Nicht-Mitglieder ist nicht gestattet.
      </p>
      <p>
        Die Anwendung wird dem Verein durch den Betreiber (Jörg Goy, Adresse
        siehe Mitgliederverzeichnis) privat (nicht gewerbllich) zur Verfügung
        gestellt.
      </p>
      <p>
        Die in FSVH Connect eingegebenen Daten, insbesondere Name, E-Mail und
        Telefonnummer, werden ausschließlich zur internen Organisation von
        Flügen verwendet und nicht an Dritte weitergegeben.
      </p>
      <p>
        Jeder Nutzer ist selbst für die Richtigkeit seiner Daten verantwortlich.
      </p>
      <p>
        Die Nutzung von FSVH Connect erfolgt auf eigene Gefahr des Nutzers. Der
        Betreiber haftet nicht für Schäden, die durch die Nutzung der
        Webanwendung entstehen.
      </p>
      <p>
        Der Betreiber behält sich das Recht vor, die Webanwendung jederzeit zu
        ändern oder einzustellen. Hieraus entstehen keine Ansprüche gegenüber
        dem Betreiber.
      </p>
      <p>
        Der Nutzer verpflichtet sich, die geltenden Gesetze und
        Rechtsvorschriften einzuhalten und keine Inhalte hochzuladen oder zu
        teilen, die gegen geltendes Recht verstoßen.
      </p>
      <h1>Datenschutzerklärung</h1>
      <p>
        Verantwortliche Stelle für die Verarbeitung der personenbezogenen Daten
        ist der Betreiber von FSVH Connect (Jörg Goy, Adresse siehe
        Mitgliederverzeichnis).
      </p>
      <p>
        Das Hosting der Applikation und die Speicherung der Anwendungsdaten
        erfolgt bei Amazon Web Services (AWS). Anbieter ist die Amazon Web
        Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg
        (nachfolgend AWS). AWS ist zertifiziert nach ISO 27001, ISO 27017, ISO
        27018 und garantiert gemäß unseren Vereinbarungen den Serverstandort
        Frankfurt, Deutschland. Weitere Informationen findest du unter:
        https://aws.amazon.com/de/privacy/?nc1=f_pr. Ich habe einen Vertrag über
        Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.
        Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
        Vertrag mit Standardvertragsklauseln, der gewährleistet, dass dieser die
        personenbezogenen Daten unserer Softwarenutzer nur nach unseren
        Weisungen und unter Einhaltung der DSGVO verarbeitet. Die Verarbeitung
        der personenbezogenen Daten erfolgt aufgrund der Notwendigkeit zur
        Erfüllung eines Vertrags gemäß Artikel 6 Absatz 1 Buchstabe b der DSGVO.
      </p>
      <p>
        Personenbezogene Daten wie Name, E-Mail-Adresse und Telefonnummer werden
        ausschließlich zur internen Organisation von Flügen verwendet und nicht
        an Dritte weitergegeben.
      </p>
      <p>
        Die Daten werden nur so lange gespeichert, wie es für die Organisation
        von Flügen notwendig ist.
      </p>
      <p>
        Die Nutzung von FSVH Connect erfolgt auf freiwilliger Basis. Der Nutzer
        hat jederzeit das Recht, seine Einwilligung zur Verarbeitung seiner
        personenbezogenen Daten zu widerrufen.
      </p>
      <p>Die Webanwendung verwendet keine Analytics- oder Tracking-Tools.</p>
      <p>
        Der Betreiber trifft angemessene technische und organisatorische
        Maßnahmen, um die Sicherheit der Daten zu gewährleisten.
      </p>
      <p>
        Bei Fragen oder Anliegen zum Datenschutz kann sich der Nutzer an den
        Betreiber wenden.
      </p>
    </Text>
  );
};
