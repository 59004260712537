import { TextInput, TextInputProps } from 'react-native';
import { Font, Style } from '~/constants';
import { useTheme } from '~/hooks';

export const MultilineInput = (props: TextInputProps) => {
  const { colors } = useTheme();

  const inputStyle = Style.apply([
    'tag',
    'center',
    props.style,
    {
      fontFamily: Font.regular,
      backgroundColor: props.value
        ? colors.backgroundActive
        : colors.backgroundInactive,
      color: props.value ? colors.primary : colors.textInactive,
      width: '100%',
      height: props.multiline ? 100 : undefined,
    },
  ]);

  return (
    <TextInput
      {...props}
      style={inputStyle}
      multiline={props.multiline ?? true}
    />
  );
};
