import * as React from 'react';
import Svg, { G, Path, Polygon, Rect, SvgProps } from 'react-native-svg';
export const Logo = (props: SvgProps) => (
  <Svg width="151px" height="59px" viewBox="0 0 151 59" {...props}>
    <G transform="translate(-335.000000, -24.000000)">
      <G id="logo" transform="translate(335.000000, 24.000000)">
        <Path
          d="M79.6111941,4.91061024 L67.2260978,0.258419369 C66.1648814,-0.136156497 64.9892821,-0.0788795745 63.9789017,0.430252079 L61.0621441,1.88764105 C60.3567842,2.24403359 60.3059473,3.23047617 60.9668252,3.65687471 L70.3017182,9.67099236 L64.0424466,12.8721569 L60.3440752,11.2238435 C59.791226,10.9756422 59.149412,10.9883703 58.6092718,11.2620288 L56.5630929,12.2866556 C55.9721158,12.5857703 55.8132511,13.3558323 56.2453634,13.8585994 L60.8905684,19.2872149 C61.2781988,19.7390696 61.8437579,20 62.434735,20 L71.1850058,20 C71.5027354,20 71.8141099,19.9236295 72.0937115,19.7836194 L89.4353883,11.1029242 C92.3902729,9.62007803 94.6779254,7.07441977 95.8408147,3.97508199 C96.4445046,2.37131651 95.2561955,0.652997177 93.540456,0.652997177 L89.8929196,0.652997177 C88.6092916,0.652997177 87.3383735,0.958475392 86.1945472,1.54397757 L79.6111931,4.91061024 L79.6111941,4.91061024 Z"
          id="Path"
          fill="#0000CC"
          fillRule="nonzero"
        />
        <Rect
          id="Rectangle"
          fill="#E6E6FF"
          x={0}
          y={25}
          width={151}
          height={34}
          rx={10}
        />
        <G
          id="FSVH-Connect"
          transform="translate(12.505500, 35.529000)"
          fill="#0000CC"
          fillRule="nonzero"
        >
          <Polygon
            id="Path"
            points="0 13.471 0 0.304 8.816 0.304 8.816 2.793 2.964 2.793 2.964 5.548 8.17 5.548 8.17 8.037 2.964 8.037 2.964 13.471"
          />
          <Path
            d="M14.497,11.21 C14.915,11.21 15.2601667,11.1751667 15.5325,11.1055 C15.8048333,11.0358333 16.0233333,10.9408333 16.188,10.8205 C16.3526667,10.7001667 16.4666667,10.5576667 16.53,10.393 C16.5933333,10.2283333 16.625,10.0446667 16.625,9.842 C16.625,9.41133333 16.4223333,9.0535 16.017,8.7685 C15.6116667,8.4835 14.915,8.17633333 13.927,7.847 C13.4963333,7.695 13.0656667,7.52083333 12.635,7.3245 C12.2043333,7.12816667 11.818,6.88116667 11.476,6.5835 C11.134,6.28583333 10.8553333,5.92483333 10.64,5.5005 C10.4246667,5.07616667 10.317,4.56 10.317,3.952 C10.317,3.344 10.431,2.79616667 10.659,2.3085 C10.887,1.82083333 11.21,1.406 11.628,1.064 C12.046,0.722 12.5526667,0.459166667 13.148,0.2755 C13.7433333,0.0918333333 14.4146667,0 15.162,0 C16.0486667,0 16.815,0.095 17.461,0.285 C18.107,0.475 18.639,0.684 19.057,0.912 L18.202,3.249 C17.8346667,3.059 17.4261667,2.89116667 16.9765,2.7455 C16.5268333,2.59983333 15.9853333,2.527 15.352,2.527 C14.6426667,2.527 14.1328333,2.62516667 13.8225,2.8215 C13.5121667,3.01783333 13.357,3.31866667 13.357,3.724 C13.357,3.96466667 13.414,4.16733333 13.528,4.332 C13.642,4.49666667 13.8035,4.6455 14.0125,4.7785 C14.2215,4.9115 14.4621667,5.03183333 14.7345,5.1395 C15.0068333,5.24716667 15.3076667,5.358 15.637,5.472 C16.321,5.72533333 16.9163333,5.9755 17.423,6.2225 C17.9296667,6.4695 18.3508333,6.75766667 18.6865,7.087 C19.0221667,7.41633333 19.2723333,7.80266667 19.437,8.246 C19.6016667,8.68933333 19.684,9.22766667 19.684,9.861 C19.684,11.0896667 19.2533333,12.0428333 18.392,12.7205 C17.5306667,13.3981667 16.2323333,13.737 14.497,13.737 C13.9143333,13.737 13.3886667,13.7021667 12.92,13.6325 C12.4513333,13.5628333 12.0365,13.4773333 11.6755,13.376 C11.3145,13.2746667 11.0041667,13.167 10.7445,13.053 C10.4848333,12.939 10.2663333,12.8313333 10.089,12.73 L10.925,10.374 C11.3176667,10.5893333 11.8021667,10.7825 12.3785,10.9535 C12.9548333,11.1245 13.661,11.21 14.497,11.21 Z"
            id="Path"
          />
          <Path
            d="M25.688,13.471 C25.1686667,12.369 24.6556667,11.2226667 24.149,10.032 C23.6423333,8.84133333 23.161,7.66333333 22.705,6.498 C22.249,5.33266667 21.8278333,4.218 21.4415,3.154 C21.0551667,2.09 20.7226667,1.14 20.444,0.304 L23.712,0.304 C23.9653333,1.064 24.2408333,1.88416667 24.5385,2.7645 C24.8361667,3.64483333 25.1401667,4.522 25.4505,5.396 C25.7608333,6.27 26.0616667,7.106 26.353,7.904 C26.6443333,8.702 26.9166667,9.39233333 27.17,9.975 C27.4106667,9.39233333 27.6798333,8.702 27.9775,7.904 C28.2751667,7.106 28.5791667,6.27 28.8895,5.396 C29.1998333,4.522 29.5038333,3.64483333 29.8015,2.7645 C30.0991667,1.88416667 30.3746667,1.064 30.628,0.304 L33.782,0.304 C33.4906667,1.14 33.155,2.09 32.775,3.154 C32.395,4.218 31.977,5.33266667 31.521,6.498 C31.065,7.66333333 30.5836667,8.84133333 30.077,10.032 C29.5703333,11.2226667 29.0573333,12.369 28.538,13.471 L25.688,13.471 Z"
            id="Path"
          />
          <Polygon
            id="Path"
            points="43.434 0.304 46.398 0.304 46.398 13.471 43.434 13.471 43.434 7.885 38.456 7.885 38.456 13.471 35.492 13.471 35.492 0.304 38.456 0.304 38.456 5.339 43.434 5.339"
          />
          <Path
            d="M60.002,13.737 C57.8613333,13.737 56.2305,13.1416667 55.1095,11.951 C53.9885,10.7603333 53.428,9.06933333 53.428,6.878 C53.428,5.78866667 53.599,4.8165 53.941,3.9615 C54.283,3.1065 54.7516667,2.3845 55.347,1.7955 C55.9423333,1.2065 56.6516667,0.76 57.475,0.456 C58.2983333,0.152 59.1913333,0 60.154,0 C60.7113333,0 61.218,0.0411666667 61.674,0.1235 C62.13,0.205833333 62.529,0.300833333 62.871,0.4085 C63.213,0.516166667 63.498,0.627 63.726,0.741 C63.954,0.855 64.1186667,0.943666667 64.22,1.007 L63.365,3.401 C62.9596667,3.18566667 62.4878333,3.002 61.9495,2.85 C61.4111667,2.698 60.8,2.622 60.116,2.622 C59.66,2.622 59.2135,2.698 58.7765,2.85 C58.3395,3.002 57.9531667,3.24583333 57.6175,3.5815 C57.2818333,3.91716667 57.0126667,4.351 56.81,4.883 C56.6073333,5.415 56.506,6.061 56.506,6.821 C56.506,7.429 56.5725,7.99583333 56.7055,8.5215 C56.8385,9.04716667 57.0538333,9.5 57.3515,9.88 C57.6491667,10.26 58.0386667,10.5608333 58.52,10.7825 C59.0013333,11.0041667 59.584,11.115 60.268,11.115 C60.6986667,11.115 61.085,11.0896667 61.427,11.039 C61.769,10.9883333 62.073,10.9281667 62.339,10.8585 C62.605,10.7888333 62.8393333,10.7096667 63.042,10.621 C63.2446667,10.5323333 63.4283333,10.45 63.593,10.374 L64.41,12.749 C63.992,13.0023333 63.403,13.2303333 62.643,13.433 C61.883,13.6356667 61.0026667,13.737 60.002,13.737 Z"
            id="Path"
          />
          <Path
            d="M75.24,8.455 C75.24,9.24033333 75.126,9.95916667 74.898,10.6115 C74.67,11.2638333 74.3406667,11.8211667 73.91,12.2835 C73.4793333,12.7458333 72.9631667,13.1036667 72.3615,13.357 C71.7598333,13.6103333 71.0853333,13.737 70.338,13.737 C69.6033333,13.737 68.9351667,13.6103333 68.3335,13.357 C67.7318333,13.1036667 67.2156667,12.7458333 66.785,12.2835 C66.3543333,11.8211667 66.0186667,11.2638333 65.778,10.6115 C65.5373333,9.95916667 65.417,9.24033333 65.417,8.455 C65.417,7.66966667 65.5405,6.954 65.7875,6.308 C66.0345,5.662 66.3765,5.111 66.8135,4.655 C67.2505,4.199 67.7698333,3.84433333 68.3715,3.591 C68.9731667,3.33766667 69.6286667,3.211 70.338,3.211 C71.06,3.211 71.7218333,3.33766667 72.3235,3.591 C72.9251667,3.84433333 73.4413333,4.199 73.872,4.655 C74.3026667,5.111 74.6383333,5.662 74.879,6.308 C75.1196667,6.954 75.24,7.66966667 75.24,8.455 Z M72.352,8.455 C72.352,7.581 72.1778333,6.89383333 71.8295,6.3935 C71.4811667,5.89316667 70.984,5.643 70.338,5.643 C69.692,5.643 69.1916667,5.89316667 68.837,6.3935 C68.4823333,6.89383333 68.305,7.581 68.305,8.455 C68.305,9.329 68.4823333,10.0225 68.837,10.5355 C69.1916667,11.0485 69.692,11.305 70.338,11.305 C70.984,11.305 71.4811667,11.0485 71.8295,10.5355 C72.1778333,10.0225 72.352,9.329 72.352,8.455 Z"
            id="Shape"
          />
          <Path
            d="M77.425,3.819 C77.9063333,3.67966667 78.527,3.54983333 79.287,3.4295 C80.047,3.30916667 80.845,3.249 81.681,3.249 C82.5296667,3.249 83.2358333,3.35983333 83.7995,3.5815 C84.3631667,3.80316667 84.8096667,4.11666667 85.139,4.522 C85.4683333,4.92733333 85.7026667,5.40866667 85.842,5.966 C85.9813333,6.52333333 86.051,7.144 86.051,7.828 L86.051,13.471 L83.22,13.471 L83.22,8.17 C83.22,7.258 83.0996667,6.612 82.859,6.232 C82.6183333,5.852 82.1686667,5.662 81.51,5.662 C81.3073333,5.662 81.092,5.6715 80.864,5.6905 C80.636,5.7095 80.4333333,5.73166667 80.256,5.757 L80.256,13.471 L77.425,13.471 L77.425,3.819 Z"
            id="Path"
          />
          <Path
            d="M88.616,3.819 C89.0973333,3.67966667 89.718,3.54983333 90.478,3.4295 C91.238,3.30916667 92.036,3.249 92.872,3.249 C93.7206667,3.249 94.4268333,3.35983333 94.9905,3.5815 C95.5541667,3.80316667 96.0006667,4.11666667 96.33,4.522 C96.6593333,4.92733333 96.8936667,5.40866667 97.033,5.966 C97.1723333,6.52333333 97.242,7.144 97.242,7.828 L97.242,13.471 L94.411,13.471 L94.411,8.17 C94.411,7.258 94.2906667,6.612 94.05,6.232 C93.8093333,5.852 93.3596667,5.662 92.701,5.662 C92.4983333,5.662 92.283,5.6715 92.055,5.6905 C91.827,5.7095 91.6243333,5.73166667 91.447,5.757 L91.447,13.471 L88.616,13.471 L88.616,3.819 Z"
            id="Path"
          />
          <Path
            d="M99.332,8.55 C99.332,7.66333333 99.4681667,6.8875 99.7405,6.2225 C100.012833,5.5575 100.370667,5.00333333 100.814,4.56 C101.257333,4.11666667 101.767167,3.781 102.3435,3.553 C102.919833,3.325 103.512,3.211 104.12,3.211 C105.538667,3.211 106.659667,3.64483333 107.483,4.5125 C108.306333,5.38016667 108.718,6.65633333 108.718,8.341 C108.718,8.50566667 108.711667,8.68616667 108.699,8.8825 C108.686333,9.07883333 108.673667,9.253 108.661,9.405 L102.239,9.405 C102.302333,9.98766667 102.574667,10.45 103.056,10.792 C103.537333,11.134 104.183333,11.305 104.994,11.305 C105.513333,11.305 106.023167,11.2575 106.5235,11.1625 C107.023833,11.0675 107.432333,10.9503333 107.749,10.811 L108.129,13.11 C107.977,13.186 107.774333,13.262 107.521,13.338 C107.267667,13.414 106.985833,13.4805 106.6755,13.5375 C106.365167,13.5945 106.032667,13.642 105.678,13.68 C105.323333,13.718 104.968667,13.737 104.614,13.737 C103.714667,13.737 102.9325,13.604 102.2675,13.338 C101.6025,13.072 101.0515,12.7078333 100.6145,12.2455 C100.1775,11.7831667 99.8545,11.2353333 99.6455,10.602 C99.4365,9.96866667 99.332,9.28466667 99.332,8.55 Z M105.982,7.467 C105.969333,7.22633333 105.928167,6.992 105.8585,6.764 C105.788833,6.536 105.681167,6.33333333 105.5355,6.156 C105.389833,5.97866667 105.206167,5.833 104.9845,5.719 C104.762833,5.605 104.487333,5.548 104.158,5.548 C103.841333,5.548 103.569,5.60183333 103.341,5.7095 C103.113,5.81716667 102.923,5.95966667 102.771,6.137 C102.619,6.31433333 102.501833,6.52016667 102.4195,6.7545 C102.337167,6.98883333 102.277,7.22633333 102.239,7.467 L105.982,7.467 Z"
            id="Shape"
          />
          <Path
            d="M110.428,8.474 C110.428,7.752 110.545167,7.07116667 110.7795,6.4315 C111.013833,5.79183333 111.352667,5.2345 111.796,4.7595 C112.239333,4.2845 112.777667,3.90766667 113.411,3.629 C114.044333,3.35033333 114.766333,3.211 115.577,3.211 C116.109,3.211 116.596667,3.2585 117.04,3.3535 C117.483333,3.4485 117.914,3.58466667 118.332,3.762 L117.743,6.023 C117.477,5.92166667 117.185667,5.833 116.869,5.757 C116.552333,5.681 116.197667,5.643 115.805,5.643 C114.969,5.643 114.345167,5.90266667 113.9335,6.422 C113.521833,6.94133333 113.316,7.62533333 113.316,8.474 C113.316,9.37333333 113.509167,10.07 113.8955,10.564 C114.281833,11.058 114.956333,11.305 115.919,11.305 C116.261,11.305 116.628333,11.2733333 117.021,11.21 C117.413667,11.1466667 117.774667,11.0453333 118.104,10.906 L118.503,13.224 C118.173667,13.3633333 117.762,13.4836667 117.268,13.585 C116.774,13.6863333 116.229333,13.737 115.634,13.737 C114.722,13.737 113.936667,13.6008333 113.278,13.3285 C112.619333,13.0561667 112.077833,12.6856667 111.6535,12.217 C111.229167,11.7483333 110.918833,11.1941667 110.7225,10.5545 C110.526167,9.91483333 110.428,9.22133333 110.428,8.474 Z"
            id="Path"
          />
          <Path
            d="M120.308,0.988 L123.139,0.532 L123.139,3.477 L126.54,3.477 L126.54,5.833 L123.139,5.833 L123.139,9.348 C123.139,9.94333333 123.2435,10.4183333 123.4525,10.773 C123.6615,11.1276667 124.082667,11.305 124.716,11.305 C125.02,11.305 125.3335,11.2765 125.6565,11.2195 C125.9795,11.1625 126.274,11.0833333 126.54,10.982 L126.939,13.186 C126.597,13.3253333 126.217,13.4456667 125.799,13.547 C125.381,13.6483333 124.868,13.699 124.26,13.699 C123.487333,13.699 122.847667,13.5945 122.341,13.3855 C121.834333,13.1765 121.429,12.8851667 121.125,12.5115 C120.821,12.1378333 120.608833,11.685 120.4885,11.153 C120.368167,10.621 120.308,10.032 120.308,9.386 L120.308,0.988 Z"
            id="Path"
          />
        </G>
      </G>
    </G>
  </Svg>
);
