const aircraft = ['D-ERRW', 'N187CM', 'D-EXPO', 'D-EIWN', 'D-ETPK'];
const types = ['VFR', 'IFR', 'Überland', 'Lokal', 'Werkverkehr'];
const newId = 'new';
const regexUUID =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
const exampleToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdE5hbWUiOiJKw7ZyZyIsImxhc3ROYW1lIjoiR295IiwiZW1haWwiOiJtYWlsQGpvZXJnZ295LmRlIiwicGhvbmUiOiIrNDkxNTExNjMzNjE5MSIsImV4cCI6MTcxMzc5OTI4MjY4NH0.QeI5MsRjnZVZXWkoVHKI_DMrK1BmLQnaKep7uPgYCIs';
const useExampleToken = true;

export const Values = {
  aircraft,
  types,
  newId,
  regexUUID,
  exampleToken,
  useExampleToken,
};
