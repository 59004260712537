import { createElement } from 'react';
import { TextInputProps, View } from 'react-native';
import { Style } from '~/constants';
import { useTheme } from '~/hooks';

export const DateInput = ({
  value,
  onChangeText,
  ...props
}: TextInputProps) => {
  const { colors } = useTheme();

  const containerStyle = Style.apply([
    'tag',
    'center',
    {
      backgroundColor: colors.backgroundActive,
    },
  ]);

  const inputStyle = Style.apply([
    'date',
    {
      color: colors.primary,
    },
  ]);

  return (
    <View style={containerStyle}>
      {createElement('input', {
        type: 'date',
        style: inputStyle,
        value,
        onInput: (event) => {
            onChangeText &&   onChangeText(event.target.value);
        },
      })}
    </View>
  );
};
