import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';
import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm as rhfUseForm,
} from 'react-hook-form';

export const useForm = <
  FormData extends FieldValues = FieldValues,
  TContext extends object = object,
>({
  defaultValues,
  resolver,
}: UseFormProps<FormData, TContext>): UseFormReturn<FormData, TContext> => {
  const isFocused = useIsFocused();
  const form = rhfUseForm<FormData, TContext>({
    defaultValues,
    resolver,
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    // Reset form on screen change
    !isFocused && form.reset();
  }, [isFocused]);

  return form;
};
