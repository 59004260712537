import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { DataStore } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { RootParams } from '~/App';
import {
  Button,
  Close,
  Compound,
  Container,
  DateInput,
  MultilineInput,
  Stack,
  TagInput,
  Text,
} from '~/components';
import { Distance, Style, Styles } from '~/constants';
import { Values } from '~/constants/Values';
import { useForm } from '~/hooks';
import { Form, Utility } from '~/methods';
import { Flight, Pilot } from '~/models';
import { useAuth } from '~/provider';

type EditProps = NativeStackScreenProps<RootParams, 'edit' | 'show'>;

const initArray = JSON.stringify([]);
const closeStyle = Style.apply('close');
const maxDescriptionLength = 140;

const formData = Form.create<Flight>({
  initialValues: [
    'start',
    'end',
    'aircraft',
    'description',
    {
      destinations: initArray,
      types: initArray,
    },
  ],
  validationRules: [
    { field: 'aircraft', type: 'string', required: true },
    { field: 'types', type: 'stringArray', required: true },
  ],
});

export const Modal = ({ navigation, route }: EditProps) => {
  const { user } = useAuth();
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [flight, setFlight] = useState<Flight>();
  const screen = route.name;
  const id = route.params?.id;
  const pilot =
    pilots.filter((pilot) => user?.email === pilot.email)[0] ??
    (user as unknown as Pilot);

  const disabled = route.name === 'show' || pilot?.email !== user?.email;

  const form = useForm<Flight>(formData);
  const formValues = form.watch();
  const destinations = JSON.parse(formValues?.destinations ?? initArray).filter(
    (destination) => destination !== '',
  );
  const types = JSON.parse(formValues?.types ?? initArray);

  const aircraftMissing = form.formState.errors.aircraft;
  const typesMissing = form.formState.errors.types;

  const onSubmit = form.handleSubmit((formData) => {
    const { start, end } = formData;
    console.log(formData, !!flight);

    const modified = {
      pilot: flight?.pilot ?? pilot?.id,
      start: Utility.toISOString(start),
      end: Utility.toISOString(end),
    };

    const nextFlight = { ...formData, ...modified };
    console.log(nextFlight);

    if (!flight) {
      DataStore.save(new Flight(nextFlight)).then(() => {
        navigation.navigate('start');
      });
    } else {
      DataStore.save(
        Flight.copyOf(flight, (updated) => {
          Form.updateModel({ formData: nextFlight, updated });
        }),
      ).then(() => {
        navigation.navigate('start');
      });
    }
  });

  useEffect(() => {
    DataStore.query(Pilot).then(setPilots);
    if (Values.regexUUID.test(id)) {
      DataStore.query(Flight).then((flights) => {
        const flight = flights.filter((flight) => flight.id === id)[0];
        setFlight(flight);
      });
    }
  }, [id]);

  useEffect(() => {
    if (flight) {
      const model = {
        ...flight,
        start: Utility.fromISOString(flight.start),
        end: Utility.fromISOString(flight.end),
        destinations: JSON.stringify(flight.destinations),
        types: JSON.stringify(flight.types),
      };
      Form.populateFromModel({ form, model });
    }
  }, [flight]);

  useEffect(() => {
    if (!formValues.start) {
      form.setValue('start', Utility.today());
    }
    if (!formValues.end) {
      form.setValue('end', Utility.today());
    }
    if (formValues.end < formValues.start) {
      form.setValue('end', formValues.start);
    }
    if (!formValues.destinations) {
      form.setValue('destinations', initArray);
    }
    if (!formValues.types) {
      form.setValue('types', initArray);
    }
  }, [formValues]);

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <Container preset={'modal'}>
        <Button
          style={closeStyle}
          onPress={() => {
            navigation.navigate('start');
          }}
        >
          <Stack preset={'row'} distance={Distance.h10} alignItems>
            <Text preset={'disclaimer'}>Schließen</Text>
            <Close />
          </Stack>
        </Button>
        <Stack distance={Distance.v20}>
          <Text preset={'title'}>
            {pilot?.firstName}'s{' '}
            {id === Values.newId && screen === 'edit' ? 'neuer ' : ''}Flug
          </Text>
          <Stack
            distance={Distance.v10}
            pointerEvents={disabled ? 'none' : 'auto'}
          >
            <Text preset={'heading'}>Zeitraum</Text>
            <Compound>
              <DateInput
                onChangeText={(value) => {
                  if (value < Utility.today()) {
                    form.setValue('start', Utility.today());
                  } else {
                    form.setValue('start', value);
                  }
                }}
                value={formValues.start}
              />
              <Text preset={'tag-text'}>bis</Text>
              <DateInput
                onChangeText={(value) => {
                  form.setValue('end', value);
                }}
                value={formValues.end}
              />
            </Compound>
          </Stack>
          <Stack
            distance={Distance.v10}
            pointerEvents={disabled ? 'none' : 'auto'}
          >
            <Text preset={'heading'}>Destinationen</Text>
            {!disabled && <Text>Trage bis zu 5 ICAO Kennungen ein.</Text>}
            <Compound>
              {(destinations.length < 5
                ? [...destinations, '']
                : destinations
              ).map((icao, index) => (
                <TagInput
                  key={index.toString()}
                  value={icao}
                  onChangeText={(value) => {
                    const nextDestinations = [...destinations];
                    nextDestinations[index] = value
                      .replace(/[\W\d]/g, '')
                      .toUpperCase();
                    form.setValue(
                      'destinations',
                      JSON.stringify(nextDestinations),
                    );
                  }}
                  placeholder={'ICAO'}
                  maxLength={4}
                />
              ))}
            </Compound>
          </Stack>
          <Stack
            distance={Distance.v10}
            pointerEvents={disabled ? 'none' : 'auto'}
          >
            <Text preset={'heading'}>Luftfahrzeug</Text>
            {!disabled && <Text>Wähle ein Luftfahrzeug aus.</Text>}
            <Compound>
              {Values.aircraft.map((aircraft, index) => {
                const isActive = formValues.aircraft === aircraft;
                return (
                  <Button
                    key={index.toString()}
                    onPress={() => {
                      if (isActive) {
                        form.setValue('aircraft', null);
                      } else {
                        form.setValue('aircraft', aircraft);
                        form.clearErrors('aircraft');
                      }
                    }}
                  >
                    <Text
                      preset={
                        isActive
                          ? 'tag-active'
                          : aircraftMissing
                          ? 'tag-negative'
                          : 'tag-inactive'
                      }
                    >
                      {aircraft}
                    </Text>
                  </Button>
                );
              })}
            </Compound>
          </Stack>
          <Stack
            distance={Distance.v10}
            pointerEvents={disabled ? 'none' : 'auto'}
          >
            <Text preset={'heading'}>Flugart</Text>
            {!disabled && <Text>Wähle eine oder mehrere Flugarten.</Text>}
            <Compound>
              {Values.types.map((type, index) => {
                const isActive = Utility.oneOf(type, types);
                return (
                  <Button
                    key={index.toString()}
                    onPress={() => {
                      if (isActive) {
                        form.setValue(
                          'types',
                          JSON.stringify(
                            types.filter(
                              (existingType) => type !== existingType,
                            ),
                          ),
                        );
                      } else {
                        form.setValue(
                          'types',
                          JSON.stringify([...types, type]),
                        );
                        form.clearErrors('types');
                      }
                    }}
                  >
                    <Text
                      preset={
                        isActive
                          ? 'tag-active'
                          : typesMissing
                          ? 'tag-negative'
                          : 'tag-inactive'
                      }
                    >
                      {type}
                    </Text>
                  </Button>
                );
              })}
            </Compound>
          </Stack>
          <Stack
            distance={Distance.v10}
            pointerEvents={disabled ? 'none' : 'auto'}
          >
            <Text preset={'heading'}>Beschreibung</Text>
            {!disabled && (
              <Stack preset={'row'}>
                <Text>Lass uns wissen, was Du vor hast.</Text>
                <Text>
                  {formValues.description?.length ?? 0}/{maxDescriptionLength}
                </Text>
              </Stack>
            )}
            <MultilineInput
              value={formValues.description}
              onChangeText={(description) => {
                form.setValue('description', description);
              }}
              maxLength={maxDescriptionLength}
              multiline
            />
          </Stack>
          {screen === 'edit' && (
            <Compound>
              <Button onPress={onSubmit}>
                <Text preset={'tag-active'}>Speichern</Text>
              </Button>
              {!!flight ? (
                <Button
                  onPress={() => {
                    DataStore.delete(Flight, flight?.id).then(() => {
                      navigation.navigate('start');
                    });
                  }}
                >
                  <Text preset={'tag-negative'}>Löschen</Text>
                </Button>
              ) : (
                <></>
              )}
            </Compound>
          )}
          {screen === 'show' && (
            <Compound>
              <a
                style={Styles.a}
                href={`mailto:${pilot?.email}?subject=Dein Flug auf FSVH Connect`}
              >
                <Text preset={'tag-active'}>E-Mail</Text>
              </a>
              <a
                style={Styles.a}
                href={`https://wa.me/${pilot?.phone.replace(
                  /00+|[\s+()]/gi,
                  '',
                )}`}
              >
                <Text preset={'tag-active'}>WhatsApp</Text>
              </a>
              <a style={Styles.a} href={`tel:${pilot?.phone}`}>
                <Text preset={'tag-active'}>Telefon</Text>
              </a>
            </Compound>
          )}
        </Stack>
      </Container>
    </ScrollView>
  );
};
