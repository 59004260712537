import { Theme as ReactNavigationTheme } from '@react-navigation/native';

export enum Color {
  blue = '#0000CC',
  blueS10B100 = '#E6E6FF',
  blueS100B50 = '#000080',
  red = '#CC0066',
  redS10B100 = '#FFE6F2',
  silver = '#F7F7FA',
  silverS1B93 = '#EBEBED',
  silverS1B20 = '#323233',
  silverS2B50 = '#7E7E80',
  white = '#FFFFFF',
  overlayLight = '#00000033', // 20% opacity
}

export enum Opacity {
  disabled = 0.5,
  pressed = 0.4,
  hovered = 0.8,
}

export type ThemeKey = keyof Theme['colors'];

export interface Theme extends ReactNavigationTheme {
  colors: ReactNavigationTheme['colors'] & {
    // Text
    title: string;
    heading: string;
    textInactive: string;
    // Elements
    backgroundOverlay: string;
    backgroundActive: string;
    backgroundInactive: string;
    backgroundNegative: string;
  };
}

export const LightTheme: Theme = {
  dark: false,
  colors: {
    primary: Color.blue,
    background: Color.white,
    card: Color.silver,
    text: Color.silverS1B20,
    notification: Color.red,
    border: Color.silverS1B20,
    // Text
    title: Color.blue,
    heading: Color.blueS100B50,
    textInactive: Color.silverS2B50,
    // Elements
    backgroundOverlay: Color.overlayLight,
    backgroundActive: Color.blueS10B100,
    backgroundInactive: Color.silverS1B93,
    backgroundNegative: Color.redS10B100,
  },
};

export const DarkTheme: Theme = LightTheme; // Todo
