import { useState } from 'react';
import {
  LayoutRectangle,
  Text,
  TextInput,
  TextInputProps,
  View,
} from 'react-native';
import { Style } from '~/constants';
import { useTheme } from '~/hooks';

export const TagInput = (props: TextInputProps) => {
  const { colors } = useTheme();
  const [layout, setLayout] = useState<LayoutRectangle>();

  const inputStyle = Style.apply([
    'tag',
    'center',
    props.style,
    {
      backgroundColor: props.value
        ? colors.backgroundActive
        : colors.backgroundInactive,
      color: props.value ? colors.primary : colors.textInactive,
    },
  ]);

  const hiddenStyle = Style.apply({ position: 'absolute', opacity: 0 });

  return (
    <View>
      <View
        style={hiddenStyle}
        pointerEvents={'none'}
        onLayout={({ nativeEvent: { layout } }) => {
          setLayout(layout);
        }}
      >
        <Text style={inputStyle}>{props.value || props.placeholder}</Text>
      </View>
      <TextInput {...props} style={[inputStyle, { width: layout?.width }]} />
    </View>
  );
};
