export enum FontSize {
  regular = 17,
  medium = 19,
  large = 34,
}

export enum Font {
  regular = 'Ubuntu_400Regular',
  medium = 'Ubuntu_500Medium',
  bold = 'Ubuntu_700Bold',
}
