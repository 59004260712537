export enum Size {
  maxWidth = 820,
  modalWidth = 640,
  modalHeight = 700,
  tag = 34,
}

export enum Distance {
  safeArea = 20,
  hitSlop = 5,
  h10 = 10,
  v6 = 6,
  v10 = 10,
  v20 = 20,
  v40 = 40,
}

export enum Radius {
  regular = 10,
}
