import React, { Fragment, isValidElement, PropsWithChildren } from 'react';
import { View, ViewProps, ViewStyle } from 'react-native';
import { Style } from '~/constants';

type StackPreset = 'column' | 'row';

type StackProps = PropsWithChildren<
  ViewProps & {
    preset?: StackPreset;
    distance?: number;
    alignItems?: boolean;
    justifyContent?: ViewStyle['justifyContent'];
    reverse?: boolean;
  }
>;

export const Stack = ({
  preset = 'column',
  distance,
  alignItems,
  justifyContent,
  reverse,
  style,
  children,
  ...props
}: StackProps) => {
  // Verify children are iterable
  if (!Array.isArray(children)) {
    return isValidElement(children) ? (
      <View {...props} style={style}>
        {children}
      </View>
    ) : null;
  }

  const filteredChildren = children.filter((child) => isValidElement(child));
  const maxIndex = filteredChildren.length - 1;

  const stackStyle = Style.apply([
    {
      flexDirection: reverse ? `${preset}-reverse` : preset,
      flexGrow: !distance && preset === 'column' ? 1 : undefined,
      justifyContent:
        !justifyContent && !distance ? 'space-between' : justifyContent,
      alignItems: alignItems ? 'center' : undefined,
    },
    style,
  ]);

  const distanceStyle = Style.apply({
    width: distance,
    height: distance,
  });

  return distance !== undefined ? (
    <View {...props} style={stackStyle}>
      {filteredChildren.map((child, index) => (
        <Fragment key={index.toString()}>
          {child}
          {index < maxIndex && <View style={distanceStyle} />}
        </Fragment>
      ))}
    </View>
  ) : (
    <View {...props} children={children} style={stackStyle} />
  );
};
