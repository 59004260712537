import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Font, FontSize } from './Fonts';
import { Opacity } from './Theming';
import { Distance, Radius, Size } from './Units';

type StyleProps = {
  [key: string]: StyleProp<ViewStyle | ImageStyle | TextStyle>;
};

const create = <T extends StyleProps>(props: T) => props;

type ApplyStyle =
  | keyof typeof Styles
  | StyleProp<ViewStyle | ImageStyle | TextStyle>;

const apply = (
  styles: ApplyStyle | ApplyStyle[],
): StyleProp<ViewStyle | ImageStyle | TextStyle> => {
  const mapStyle = (style: ApplyStyle) =>
    typeof style === 'string' ? Styles[style] : style;

  if (Array.isArray(styles)) {
    const mappedStyles = (styles as ApplyStyle[]).map(mapStyle);
    return Object.assign({}, ...mappedStyles);
  } else {
    return mapStyle(styles);
  }
};

export const Styles = create({
  a: { display: 'flex', textDecorationLine: 'none' },
  flex: { flex: 1 },
  center: { justifyContent: 'center', alignItems: 'center' },
  container: { alignItems: 'center' },
  contentContainer: {
    width: '100%',
    maxWidth: Size.maxWidth,
    padding: Distance.safeArea,
  },
  modalContainer: {
    width: '100%',
    maxWidth: Size.modalWidth,
    borderRadius: Distance.v10,
    overflow: 'hidden',
  },
  safeArea: { padding: Distance.safeArea },
  tag: {
    height: Size.tag,
    fontFamily: Font.bold,
    fontSize: FontSize.medium,
    paddingVertical: Distance.v6,
    paddingHorizontal: Distance.h10,
    borderRadius: Radius.regular,
    overflow: 'hidden',
    alignSelf: 'flex-start',
  },
  listItem: {
    borderRadius: Radius.regular,
    padding: Distance.h10,
  },
  date: {
    fontFamily: Font.bold,
    fontSize: FontSize.medium,
    overflow: 'visible',
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  compound: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  // Opacity
  hovered: {
    opacity: Opacity.hovered,
  },
  pressed: {
    opacity: Opacity.pressed,
  },
  disabled: {
    opacity: Opacity.disabled,
  },
  close: {
    alignSelf: 'flex-end',
  },
});

export const Style = { create, apply };
