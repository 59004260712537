import AsyncStorage from '@react-native-async-storage/async-storage';
import { DataStore } from 'aws-amplify';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useJwt } from 'react-jwt';
import { tokenBridge } from './TokenBridge';

interface AuthContextProps {
  user?: UserToken;
  isCurrent?: boolean;
  jwt?: string;
  setJwt: React.Dispatch<React.SetStateAction<string>>;
}

export interface UserToken {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  exp: number;
}

type AuthProviderProps = PropsWithChildren<{}>;

const AuthContext = createContext<AuthContextProps>({} as any);

export const AuthProvider = (props: AuthProviderProps) => {
  const [jwt, setJwt] = useState<string>();
  const { decodedToken } = useJwt(jwt);
  const user = decodedToken as UserToken;
  const isCurrent = user?.exp > Date.now();

  useEffect(() => {
    if (!jwt) {
      AsyncStorage.getItem('jwt').then((cachedJwt) => {
        if (cachedJwt) {
          setJwt(cachedJwt);
        }
      });
    } else {
      tokenBridge.setToken(jwt);
    }
  }, [jwt]);

  useEffect(() => {
    if (user && !isCurrent) {
      DataStore.clear();
    }
  }, [user, isCurrent]);

  const authContext: AuthContextProps = {
    user,
    isCurrent,
    jwt,
    setJwt,
  };

  return <AuthContext.Provider {...props} value={authContext} />;
};

export const useAuth = () => useContext<AuthContextProps>(AuthContext);
