import { View, ViewProps } from 'react-native';
import { Style } from '~/constants';
import { useTheme } from '~/hooks';

type ContainerPreset = 'default' | 'silver' | 'modal';

type ContainerProps = ViewProps & {
  preset?: ContainerPreset;
};

export const Container = ({ preset = 'default', ...props }: ContainerProps) => {
  const { colors } = useTheme();

  const containerStyle = Style.apply([
    preset === 'modal' ? 'modalContainer' : 'container',
    {
      backgroundColor: preset === 'silver' ? colors.card : colors.background,
    },
  ]);

  const contentContainerStyle = Style.apply('contentContainer');

  return (
    <View style={[containerStyle, props.style]}>
      <View {...props} style={[contentContainerStyle, props.style]} />
    </View>
  );
};
