import { isValidElement } from 'react';
import { View, ViewProps } from 'react-native';
import { Distance, Style } from '~/constants';

const compoundStyle = Style.apply('compound');

export const Compound = ({ children, style, ...props }: ViewProps) => {
  // Verify children are iterable
  if (!Array.isArray(children)) {
    return isValidElement(children) ? (
      <View {...props} style={style}>
        {children}
      </View>
    ) : null;
  }

  return (
    <View
      {...props}
      children={children.map((child) => ({
        ...child,
        props: {
          ...child.props,
          style: {
            ...child.props.style,
            marginBottom: Distance.v10,
            marginRight: Distance.h10,
          },
        },
      }))}
      style={[compoundStyle, style]}
    />
  );
};
