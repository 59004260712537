import * as React from "react";
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg";
export const Close = (props: SvgProps) => (
  <Svg width="34px" height="34px" viewBox="0 0 34 34" {...props}>
    <G id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G
        id="iPad-Air-10.9\u201D-Copy"
        transform="translate(-676.000000, -321.000000)"
      >
        <G id="close" transform="translate(676.000000, 321.000000)">
          <Circle id="Oval" fill="#EBEBED" cx={17} cy={17} r={17} />
          <Path
            d="M20.9076179,12.2826167 C21.3714851,11.8187495 22.1248049,11.8187495 22.5886721,12.2826167 C23.0525393,12.7464839 23.0525393,13.4998049 22.5886715,13.9636721 L18.6810542,17.875 L22.5923816,21.7826179 C23.0562488,22.2464851 23.0562488,22.9998049 22.5923816,23.4636721 C22.1285144,23.9275393 21.3751945,23.9275393 20.9113273,23.4636721 L16.9999994,19.5560542 L13.0923821,23.4673821 C12.6285149,23.9312493 11.8751945,23.9312493 11.4113273,23.4673821 C10.9474601,23.0035149 10.9474601,22.2501951 11.4113273,21.7863279 L15.3189446,17.875 L11.4076167,13.9673833 C10.9437495,13.5035161 10.9437495,12.7501951 11.4076167,12.2863279 C11.8714839,11.8224607 12.6248043,11.8224607 13.0886715,12.2863279 L17,16.1939452 Z"
            id="Combined-Shape"
            fill="#7E7E80"
          />
        </G>
      </G>
    </G>
  </Svg>
);
