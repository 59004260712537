import { View } from 'react-native';
import { Distance, Style } from '~/constants';
import { useTheme } from '~/hooks';
import { Flight, Pilot } from '~/models';
import { Compound } from './Compound';
import { Stack } from './Stack';
import { Text } from './Text';

interface ListItemProps {
  flight: Flight;
  pilot: Pilot;
}

const stackStyle = Style.apply('flex');

export const ListItem = ({ flight, pilot }: ListItemProps) => {
  const { colors } = useTheme();
  const containerStyle = Style.apply([
    'listItem',
    { backgroundColor: colors.card },
  ]);

  const compoundContent: string[] = [
    flight.aircraft,
    ...flight.destinations,
    ...flight.types,
  ];

  return (
    <View style={containerStyle}>
      <Stack preset={'row'} distance={Distance.safeArea}>
        <Text preset={'strong'}>
          {new Date(flight.start).toLocaleDateString('de', {
            weekday: 'short',
            day: '2-digit',
            month: '2-digit',
          })}
        </Text>
        <Stack distance={Distance.v10} style={stackStyle}>
          {flight.description && <Text>{flight.description}</Text>}
          <Compound>
            {compoundContent.map((destination, index) => (
              <Text key={index.toString()} preset={'tag-active'}>
                {destination}
              </Text>
            ))}
          </Compound>
        </Stack>
        <Text preset={'strong'}>
          {pilot?.firstName} {pilot?.lastName}
        </Text>
      </Stack>
    </View>
  );
};
