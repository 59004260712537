import {
  Ubuntu_400Regular,
  Ubuntu_500Medium,
  Ubuntu_700Bold,
  useFonts,
} from '@expo-google-fonts/ubuntu';
import {
  DocumentTitleOptions,
  LinkingOptions,
  NavigationContainer,
} from '@react-navigation/native';
import {
  NativeStackNavigationOptions,
  createNativeStackNavigator,
} from '@react-navigation/native-stack';
import { Platform } from 'react-native';
import { LightTheme } from '~/constants/Theming';
import { Modal, Start, Terms } from '~/pages';
import { Style } from './constants';
import { useTheme } from './hooks';
import { Utility } from './methods';
import { AuthProvider } from './provider';

enum LogLevel {
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  VERBOSE = 'VERBOSE',
  NONE = 'NONE',
}

if (Platform.OS === 'web') {
  (window as any).LOG_LEVEL = LogLevel.WARN;
}

export type RootParams = {
  start: { jwt?: string };
  edit: { id: string };
  show: { id: string };
  terms: undefined;
};

const domainPrefix = `https://connect.joey.aero`;
const prefixes = [domainPrefix];
const linking: LinkingOptions<RootParams> = {
  prefixes,
  config: {
    initialRouteName: 'start',
    screens: {
      start: { path: 'start' },
      edit: { path: 'edit/:id' },
      show: { path: 'show/:id' },
      terms: { path: 'terms' },
    },
  },
};

export const App = () => {
  const [fontsLoaded] = useFonts({
    Ubuntu_400Regular,
    Ubuntu_500Medium,
    Ubuntu_700Bold,
  });

  const documentTitle: DocumentTitleOptions = {
    // Set document title for Web
    formatter: (options, route) =>
      `FSVH Connect - ${Utility.capitalize(route?.name ?? '')}`,
  };

  return (
    <AuthProvider>
      <NavigationContainer
        linking={linking}
        theme={LightTheme}
        documentTitle={documentTitle}
      >
        {fontsLoaded && <Root />}
      </NavigationContainer>
    </AuthProvider>
  );
};

const { Navigator, Group, Screen } = createNativeStackNavigator<RootParams>();

const Root = () => {
  const { colors } = useTheme();
  const staticOptions: NativeStackNavigationOptions = { headerShown: false };
  const modalOptions: NativeStackNavigationOptions = {
    headerShown: false,
    presentation: 'transparentModal',
    animation: 'fade',
    contentStyle: Style.apply([
      'safeArea',
      'center',
      {
        backgroundColor: colors.backgroundOverlay,
      },
    ]),
  };

  return (
    <Navigator>
      <Group screenOptions={staticOptions}>
        <Screen name={'start'} component={Start} />
      </Group>
      <Group screenOptions={modalOptions}>
        <Screen name={'show'} component={Modal} />
        <Screen name={'edit'} component={Modal} />
        <Screen name={'terms'} component={Terms} />
      </Group>
    </Navigator>
  );
};
