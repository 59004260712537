import React from 'react';
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  ViewStyle,
} from 'react-native';
import { Distance, Style } from '~/constants';

export type ButtonProps = PressableProps & {
  label?: string;
  onHoverIn?: (event: MouseEvent) => void;
  onHoverOut?: (event: MouseEvent) => void;
};

type PressableStateCallbackTypeWeb = PressableStateCallbackType & {
  hovered?: boolean;
};

const hoveredStyle = Style.apply('hovered');
const pressedStyle = Style.apply('pressed');
const disabledStyle = Style.apply('disabled');

export const Button = ({
  label,
  disabled,
  style,
  children,
  ...props // includes hover props for web platform
}: ButtonProps) => {
  return (
    <Pressable
      {...props}
      onPress={(event) => {
        if (props.onPress) {
          props.onPress(event);
        }
      }}
      onLongPress={(event) => {
        if (props.onLongPress) {
          props.onLongPress(event);
        }
      }}
      disabled={disabled}
      hitSlop={props.hitSlop || Distance.hitSlop}
      style={({ pressed, hovered }: PressableStateCallbackTypeWeb) => [
        !disabled && hovered && hoveredStyle,
        !disabled && pressed && pressedStyle,
        disabled && disabledStyle,
        style as ViewStyle,
      ]}
    >
      {children}
    </Pressable>
  );
};
